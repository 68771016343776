import { api } from "@/API/Intercepter";
import UserInfo from "./userInfoService";
import router from "@/router";
const setup = (store) => {
  api.interceptors.request.use(
    (config) => {
      const token = UserInfo.getLocalAccessToken();
      if (token) {
        config.headers["Authorization"] = "Bearer " + token; // for Spring Boot back-end
        // config.headers["x-access-token"] = token; // for Node.js Express back-end
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  api.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;

      if (originalConfig.url !== "/auth/signin" && err.response) {
        // Access Token was expired
        if (
          err.response.status === 401 &&
          !originalConfig._retry &&
          err.response.data.message &&
          err.response.data.message === "Unauthenticated."
        ) {
          store.dispatch("logout");
        }
        // if has no permission
        else if (err.response.status === 403) {
          router.push({ name: "NotAuthorized" });
        } else if (err.response.status === 401) {

          if (err.response.data.message == "Your mobile number is not verified") {
            router.push({ path: `/auth/confirm-mobile-number` });
          } else if (
            err.response.data.message == "Your email address is not verified"
          ) {
            router.push({ path: `/auth/confirm-mail` });
          }
        } else if (err.response.status === 500 || err.response.status === 504) {
          //router.push({ name: "ServerError" });
        } else if (
          err.response.status === 400 &&
          err.response.data.data == "should update info"
        ) {
          router.push({ path: `/user/should-update-info` });
        }
      }

      return Promise.reject(err);
    }
  );
};

export default setup;
